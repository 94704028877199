:root {
  --blue-light01: #E0E8F9;
  --blue-light02: #CDDAF4;
  --blue-light03: #B2C5EB;
  --blue-medium01: #94AFE1;
  --blue-medium02: #7B99CF; /* inQ brand color */
  --blue-medium03: #5273AD ;
  --blue-dark01: #415C8B;
  --blue-dark02: #314468;
  --blue-dark03: #273753;

  --brandWhite: #fff;
  --neutral-light01: #F5F7FA;
  --neutral-light02: #E4E7EB;
  --neutral-light03: #CBD2D9;
  --neutral-medium01: #9AA5B1;
  --neutral-medium02: #7B8794;
  --neutral-medium03: #616E7C;
  --neutral-medium04: #52606D;
  --neutral-dark01: #3E4C59;
  --neutral-dark02: #323F4B;
  --neutral-dark03: #1F2933;

  --top-bar-height: 48px;
  --footer-height: 40px;
}

.iframe-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.iframe-content {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  transform: scale(0.9); /* Scale the iframe content */
  transform-origin: top left;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local('Inter Regular'), local('Inter-Regular'),
  url('fonts/woff2/Inter-Regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('fonts/woff/Inter-Regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
  url('./fonts/ttf/Montserrat-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
  url('./fonts/ttf/Montserrat-Medium.ttf') format('ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
  url('./fonts/ttf/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: bold;
  font-weight: 700;
  src: url('./fonts/ttf/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), local('SourceSans3-Regular'),
  url('./fonts/ttf/SourceSans3-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 500;
  src: local('Source Sans Pro Medium'), local('SourceSansPro-Medium'), local('SourceSans3-Medium'),
  url('./fonts/ttf/SourceSans3-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'), local('SourceSans3-SemiBold'),
  url('./fonts/ttf/SourceSans3-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: bold;
  font-weight: 700;
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), local('SourceSans3-Bold'),
  url('./fonts/ttf/SourceSans3-Bold.ttf') format('truetype');
}

body {
  font-family: Montserrat, sans-serif;
  color: var(--blue-dark03);
  font-size: 14px;
  line-height: 20px;
}

/* Makes sure the footer stays at the bottom */
.App {
  display: flex;
  flex-direction: column;
}

.react-grid-layout {
  min-height: calc(100vh - var(--footer-height) - var(--top-bar-height));
  background-color: var(--neutral-light01);
}

h1 {
  margin-top: 0px;
  margin-bottom: 16px;
  color: var(--blue-dark03);
  font-size: 30px;
  line-height: 38px;
  font-weight: 600;
}

h2 {
  margin-top: 0px;
  margin-bottom: 16px;
  color: var(--blue-dark03);
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}

h3 {
  margin-top: 0px;
  margin-bottom: 14px;
  color: var(--blue-dark03);
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}

h4 {
  margin-top: 0px;
  margin-bottom: 4px;
  color: var(--blue-dark03);
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}

h5 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: var(--blue-dark03);
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

h6 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: var(--blue-dark03);
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

a {
  color: #3f4e55;
  font-weight: 600;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.navbar {
  display: flex;
  align-items: center;
  height: var(--top-bar-height);
  padding: 0 32px;
  background-color: var(--brandWhite);
  color: var(--neutral-dark03);
}

.navbar__logo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  margin-right: 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar__menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
}

.nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.nav__link {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  margin: 0 1px;
  padding: 6px 10px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: var(--blue-dark03);
  border-radius: 2px;
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;
}

.nav__link.is--dropdown {
  font-weight: 700;
  letter-spacing: .03rem;
  color: var(--blue-light01);
}

.nav__link.is--dropdown:hover .nav__dropdown{
  display:block;
}

.nav__link:hover {
  text-decoration: none;
}

/* For links that are actual links leading to external pages */
.dropdown-link {
  font-weight: 400;
  color: var(--blue-dark01) !important;
}

.dropdown-link:hover {
  text-decoration: none;
}

.dead-link {
  color: grey;
  cursor: not-allowed;
}

.navbar__inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.nav__icon {
  display: block;
  margin-right: 2px;
  margin-left: 2px;
  padding: 10px;
  border-radius: 6px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #ededed;
  font-size: 16px;
  line-height: 0;
  text-decoration: none;
}

.nav__icon:hover {
  background-color: #ededed;
}

.nav__icon-inner {
  line-height: 0;
}

.nav__dropdown {
  position: absolute;
  left: 0%;
  top: 100%;
  right: auto;
  bottom: auto;
  z-index: 1;
  display: none;
  overflow: hidden;
  border: 1px solid var(--blue-light03);
  border-radius: 2px;
  background-color: #f9fafb;
  box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0 20px 24px -4px rgba(16, 24, 40, 0.08);
}

.nav__dropdown-item {
  display: flex;
  align-items: center;
  position: relative;
  margin: 5px;
  padding: 6px 10px;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 400;
  color: var(--blue-dark01);
  border-radius: 2px;
}

.nav__dropdown-item:hover {
  background-color: var(--blue-light01);
  color: var(--blue-dark02);
}

.nav__link-icon {
  margin-left: 2px;
}

.nav__link-text {
  display: flex;
  align-items: center;
}

.nav--has-divider {
  margin: 0 5px;
  padding: 0 10px;
  color: var(--blue-medium01);
  text-transform: uppercase;
  font-size: 10px;
}

.nav--has-divider::before {
  content:"";
  display: block;
  border-bottom: 1px solid var(--blue-light01);
  margin-bottom:11px;
}

.nav--is-after-divider {
  margin-top: 0;
}

.is--dead-link:hover {
  background-color: var(--neutral-light02);
  color: var(--neutral-dark03);
}

.top_bar {
  box-shadow: 0px 0px 5px var(--neutral-medium01);
  z-index: 10;
  background-color: var(--blue-dark01);
}

.top_bar .nav__link:hover,
.top_bar .nav__link:active,
.top_bar .nav__link:focus {
  background-color: var(--blue-medium03);
}

.footer {
  width: 100%;
  height: var(--footer-height);
  bottom: 0;
  padding: 0;
}

.footer .nav__link {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--neutral-medium02);
}

.footer .nav__link:hover {
  color: var(--neutral-medium04);
  box-sizing: content-box;
}

.footer .navbar__menu {
  padding: 0 2rem;
}

.logout__link:hover {
  color: var(--neutral-medium04);
}

.logout__icon {
  margin-right: 5px;
}

.react-grid {
  display: -ms-grid;
  display: grid;
  padding: 24px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-auto-columns: 1fr;
  grid-auto-rows: 96px;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: 96px;
  grid-template-rows: 96px;
}

.search-wrapper {
  overflow: hidden;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid var(--blue-light01);
  box-shadow: 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)
}

.card__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--blue-light01);
  cursor: -webkit-grab;
  cursor: grab;
}

.card__title {
  color: var(--blue-medium03);
  text-transform: uppercase;
  letter-spacing: .03rem;
  font-size: 10px;
  line-height: 24px;
  font-weight: 600;
}

.card_controls {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 0;
}

.control-button {
  margin-right: 2px;
  margin-left: 2px;
  padding: 8px;
  border-radius: 50%;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: var(--neutral-medium04);
  line-height: 0;
}

.control-button:hover {
  background-color: #ededed;
  color: #3f4e55;
}

.control-button.is--active {
  background-color: #ededed;
  color: #3f4e55;
}

.card__body {
  overflow: auto;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.card__footer {
  line-height: 0;
  text-align: right;
}

.resize-handle {
  padding: 4px 0px 0px 4px;
  line-height: 0;
}

.card__body-inner {
  padding: 16px;
}

.label__text {
  color: var(--neutral-medium04);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.button {
  padding: 10px 18px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #d0d5dd;
  border-radius: 30px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #344054;
  font-family: Source Sans Pro, sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  letter-spacing: .01rem;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

.button:hover {
  background-color: #f9fafb;
  text-decoration: none;
}

.button.is--primary {
  background-color: var(--blue-medium02);
  color: var(--brandWhite);
  min-width: 40px;
  justify-content: center;
  border: 1px solid var(--blue-medium03);
}

.button.is--primary:hover {
  background-color: var(--blue-medium03);
}

.button.is--primary:disabled {
  border: 0px
}

.button.is--primary:active {
  background-color: var(--blue-dark01);
}

.button.is--secondary {
  background-color: var(--brandWhite);
  color: var(--neutral-medium04);
  border-color: var(--neutral-light02);
}

.button.is--secondary:hover {
  background-color: var(--blue-light01);
  border-color: var(--blue-light02);
}

.button.is--active {
  background-color: #ededed;
  color: #3f4e55;
}

.button.is--active:hover {
  background-color: #ededed;
}

.button.is--block {
  display: block;
  text-align: center;
}

.button__icon {
  display: inline-block;
  margin-right: 6px;
  line-height: 0;
}

.span {
  display: inline-block;
}

.search {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.search__input {
  margin-right: 8px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.placeholder {
  padding: 8px 16px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #f9fafb;
}

.filter__tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
  border-bottom: 1px solid #eaecf0;
}

.filter__label {
  margin-right: 8px;
  font-weight: 600;
}

.filter__tab {
  margin-right: 8px;
  margin-left: 8px;
  padding-right: 4px;
  padding-bottom: 8px;
  padding-left: 4px;
  border-bottom: 2px solid transparent;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #667085;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.filter__tab:hover {
  border-bottom-color: #3f4e55;
  color: #3f4e55;
  text-decoration: none;
}

.filter__tab.is--active {
  border-bottom: 2px solid #3f4e55;
  color: #3f4e55;
}

.table-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.card__filter {
  margin: 16px;
}

/* SEARCH RESULTS */
.MuiDataGrid-columnHeaderTitle {
  color: var(--blue-dark01);
  text-transform: uppercase;
  font-size: 12px;
}

.MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator {
  color: var(--blue-light01);
}

.stakeholder-wrapper {
  overflow: hidden;
}

.stakeholder {
  position: relative;
  margin-bottom: -1px;
  padding: 8px 16px 24px;
  border-bottom: 1px solid #eaecf0;
}

.stakeholder-profile {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.stakeholder-picture {
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
  border-style: solid;
  border-width: 4px;
  border-color: #fff;
  border-radius: 50%;
  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03), 0 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.stakeholder-social {
  margin-top: 42px;
}

.stakeholder-img {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  min-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.social__link {
  display: inline-block;
  padding-right: 8px;
  padding-left: 8px;
}

.stakeholder__bg {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  height: 42px;
  background-color: #ededed;
}

.stakeholder__title {
  margin-bottom: 4px;
  color: #101828;
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}

.stakeholder__subtitle {
  color: #344054;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.card-section {
  padding: 24px 16px;
  border-top: 1px solid #eaecf0;
}

.card-section__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.card-section__title {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #101828;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
}

.meta {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #344054;
}

.meta__title {
  color: #101828;
  font-weight: 600;
}
.meta__title::first-letter {
  text-transform:capitalize;
}


.meta__data{
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: end;
  display: block;
}

.channel-activity {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 16px;
  padding: 16px;
  border-radius: 12px;
  background-color: #f5faff;
}

.channel-activity__icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40px;
  height: 40px;
  margin-right: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 50%;
  background-color: #ededed;
}

.channel-activity__title {
  color: #114369;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.channel-activity__icon-img {
  width: 20px;
  height: 20px;
}

.card__scroll-area {
  overflow: auto;
}

.document-wrapper {
  overflow: hidden;
}

.document-title {
  margin-bottom: -1px;
  padding: 16px 16px 24px;
  border-bottom: 1px solid #eaecf0;
}

.document-title__title {
  margin-top: 0px;
  margin-bottom: 4px;
  color: #101828;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
}

.document-title__subtitle {
  color: #344054;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 16px;
}

.related-profile {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 16px;
  border-radius: 12px;
}

.related-profile__avatar {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 40px;
  height: 40px;
  margin-right: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 50%;
  background-color: #ededed;
  color: #344054;
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
}

.related-profile__avatar-img {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  width: 100%;
}

.related-profile__title {
  color: #101828;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.related-profile__subtitle {
  color: #344054;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.related-profile__name {
  margin-right: 8px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.related-profile__link {
  margin-top: 10px;
  cursor: pointer;
}

.badge {
  display: inline-block;
  margin-right: 8px;
  margin-left: 8px;
  padding: 2px 8px;
  border-radius: 16px;
  background-color: #ededed;
  color: #3f4e55;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.card__tabs {
  margin: 16px;
}

.tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -8px;
  margin-left: -8px;
}

.tabs__tab {
  margin-right: 8px;
  margin-left: 8px;
  padding: 8px 12px;
  border-radius: 6px;
  background-color: transparent;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #667085;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  cursor: pointer;
}

.tabs__tab:hover {
  background-color: #ededed;
  color: #3f4e55;
}

.tabs__tab.is--active {
  background-color: #ededed;
  color: #3f4e55;
}

.card__table {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.modal-wrapper {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(52, 64, 84, 0.7);
}

.modal {
  width: 100%;
  max-width: 480px;
  border-radius: 11px;
  background-color: #fff;
}

.modal__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.modal__section {
  padding: 16px;
}

.modal__title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #101828;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

.setting {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
}

.setting__label {
  -webkit-box-flex: 0;
  -webkit-flex: 0 50%;
  -ms-flex: 0 50%;
  flex: 0 50%;
  color: #344054;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.modal__footer {
  padding: 16px;
  border-top: 1px solid #eaecf0;
}

.modal__actions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -8px;
  margin-left: -8px;
}

.modal__actions-item {
  padding-right: 8px;
  padding-left: 8px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.modal__title-icon {
  margin-right: 8px;
}

.link {
  color: #3f4e55;
  font-weight: 600;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

@media screen and (min-width: 1920px) {
  .react-grid {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 991px) {
  .react-grid {
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .react-grid {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

#w-node-_6b0231db-35d4-198e-6086-8daaffc0ec2e-7d540d71 {
  -ms-grid-column: span 5;
  grid-column-start: span 5;
  -ms-grid-column-span: 5;
  grid-column-end: span 5;
  -ms-grid-row: span 2;
  grid-row-start: span 2;
  -ms-grid-row-span: 2;
  grid-row-end: span 2;
}

#w-node-_9fca7396-6bad-d7a2-6385-0e24901d16ba-7d540d71 {
  -ms-grid-column: span 5;
  grid-column-start: span 5;
  -ms-grid-column-span: 5;
  grid-column-end: span 5;
  -ms-grid-row: span 2;
  grid-row-start: span 2;
  -ms-grid-row-span: 2;
  grid-row-end: span 2;
}

#w-node-a865c1fa-db1a-fb3e-4ed1-8ded1d27fcb0-7d540d71 {
  -ms-grid-column: span 6;
  grid-column-start: span 6;
  -ms-grid-column-span: 6;
  grid-column-end: span 6;
  -ms-grid-row: span 5;
  grid-row-start: span 5;
  -ms-grid-row-span: 5;
  grid-row-end: span 5;
}

#w-node-ba6c24ed-9460-6e47-ef58-4e1a70cc0907-7d540d71 {
  -ms-grid-column: span 6;
  grid-column-start: span 6;
  -ms-grid-column-span: 6;
  grid-column-end: span 6;
  -ms-grid-row: span 5;
  grid-row-start: span 5;
  -ms-grid-row-span: 5;
  grid-row-end: span 5;
}

#w-node-_0e274c19-ca19-cad7-dec2-2236735b0a39-7d540d71 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 6;
  grid-row-start: span 6;
  -ms-grid-row-span: 6;
  grid-row-end: span 6;
}

#w-node-_560fa825-ae11-8c4c-d256-03be072bc0a6-7d540d71 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 6;
  grid-row-start: span 6;
  -ms-grid-row-span: 6;
  grid-row-end: span 6;
}

#w-node-_03be884a-8bac-0101-a3f3-afed708c5942-7d540d71 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 6;
  grid-row-start: span 6;
  -ms-grid-row-span: 6;
  grid-row-end: span 6;
}

#w-node-_164d5127-30d2-00b8-c6e4-2841b9559f68-7d540d71 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 6;
  grid-row-start: span 6;
  -ms-grid-row-span: 6;
  grid-row-end: span 6;
}

#w-node-a167e375-caf4-7433-85f4-ebcf660cc975-7d540d71 {
  -ms-grid-column: span 6;
  grid-column-start: span 6;
  -ms-grid-column-span: 6;
  grid-column-end: span 6;
  -ms-grid-row: span 6;
  grid-row-start: span 6;
  -ms-grid-row-span: 6;
  grid-row-end: span 6;
}

#w-node-_6b0231db-35d4-198e-6086-8daaffc0ec2e-752d1aa0 {
  -ms-grid-column: span 5;
  grid-column-start: span 5;
  -ms-grid-column-span: 5;
  grid-column-end: span 5;
  -ms-grid-row: span 2;
  grid-row-start: span 2;
  -ms-grid-row-span: 2;
  grid-row-end: span 2;
}

#w-node-_9fca7396-6bad-d7a2-6385-0e24901d16ba-752d1aa0 {
  -ms-grid-column: span 5;
  grid-column-start: span 5;
  -ms-grid-column-span: 5;
  grid-column-end: span 5;
  -ms-grid-row: span 2;
  grid-row-start: span 2;
  -ms-grid-row-span: 2;
  grid-row-end: span 2;
}

#w-node-a865c1fa-db1a-fb3e-4ed1-8ded1d27fcb0-752d1aa0 {
  -ms-grid-column: span 6;
  grid-column-start: span 6;
  -ms-grid-column-span: 6;
  grid-column-end: span 6;
  -ms-grid-row: span 5;
  grid-row-start: span 5;
  -ms-grid-row-span: 5;
  grid-row-end: span 5;
}

#w-node-ba6c24ed-9460-6e47-ef58-4e1a70cc0907-752d1aa0 {
  -ms-grid-column: span 6;
  grid-column-start: span 6;
  -ms-grid-column-span: 6;
  grid-column-end: span 6;
  -ms-grid-row: span 5;
  grid-row-start: span 5;
  -ms-grid-row-span: 5;
  grid-row-end: span 5;
}

#w-node-_0e274c19-ca19-cad7-dec2-2236735b0a39-752d1aa0 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 6;
  grid-row-start: span 6;
  -ms-grid-row-span: 6;
  grid-row-end: span 6;
}

#w-node-_560fa825-ae11-8c4c-d256-03be072bc0a6-752d1aa0 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 6;
  grid-row-start: span 6;
  -ms-grid-row-span: 6;
  grid-row-end: span 6;
}

#w-node-_03be884a-8bac-0101-a3f3-afed708c5942-752d1aa0 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 6;
  grid-row-start: span 6;
  -ms-grid-row-span: 6;
  grid-row-end: span 6;
}

#w-node-_164d5127-30d2-00b8-c6e4-2841b9559f68-752d1aa0 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 6;
  grid-row-start: span 6;
  -ms-grid-row-span: 6;
  grid-row-end: span 6;
}

#w-node-a167e375-caf4-7433-85f4-ebcf660cc975-752d1aa0 {
  -ms-grid-column: span 6;
  grid-column-start: span 6;
  -ms-grid-column-span: 6;
  grid-column-end: span 6;
  -ms-grid-row: span 6;
  grid-row-start: span 6;
  -ms-grid-row-span: 6;
  grid-row-end: span 6;
}

@media screen and (min-width: 1920px) {
  #w-node-_0e274c19-ca19-cad7-dec2-2236735b0a39-7d540d71 {
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }

  #w-node-_0e274c19-ca19-cad7-dec2-2236735b0a39-752d1aa0 {
    -ms-grid-column: span 3;
    grid-column-start: span 3;
    -ms-grid-column-span: 3;
    grid-column-end: span 3;
  }
}

/* ADDITIONAL */

.button{
  display: inline-flex;
}
.button-group .button{
  margin: 0 12px 16px 0;
}

.w-embed:after{
  content: none;
}
.w-embed:before{
  content: none;
}





/* Search Inputs */
.search label{
  font-size: 16px;
  line-height: 0.7;
  overflow: inherit;
  color: rgba(0, 0, 0, 0.37);
}
.search .input-wrapper{
  border-radius: 8px;
  padding: 0;
}
.search input{
  padding: 10px 14px;
  border-radius: 30px;
}

.search .Mui-focused {
  border-color: var(--neutral-dark03);
}

.search .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-radius: 30px;
}

.MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--neutral-light02);
}

.MuiAutocomplete-root .MuiOutlinedInput-root{
  padding: 3px !important;
}  

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px 14px !important;
}

/* MODIFIERS */
.control-button{
  margin-left: 2px !important;
  margin-right: 2px !important;
  padding: 8px !important;
}

.control-button:hover {
  background-color: var(--blue-light02) !important;
}

.control-button svg {
  font-size: 16px !important;
  width: 16px !important;
  height: 16px !important;
  color: var(--blue-medium03) !important;
}


.clickable_datagrid_cells{
  cursor: pointer;
  color: var(--blue-dark01);
  font-weight: 500;
}

.MuiDataGrid-root{
  border: none !important;
  border-radius: 0 !important;
  border-top: 1px solid #eaecf0 !important;
}

.MuiDataGrid-columnHeaders{
  background: #fafdff;
}

.underline-link {
  text-decoration: underline;
  display: block;
  margin-bottom: 15px;
}

.large-link {
  line-height: 20px;
  font-size: 20px;
}

.block-gap {
  margin-bottom: 15px;
}

.form-container {
  display: flex;
}

.dropdown-form {
  margin-right: 10px !important;
}

.helper-text {
  margin: 0;
  color: grey;
}

.error-text {
  color: red;
  opacity: 0.7;
}

.padding-0 {
  padding: 0px;
}

.chat-container {
  border-right: 1px solid #e7e7e7;
}

.chat-body {
  padding: 20px;
  height: 620px;
  overflow-y: auto;
  text-align: center;
}

.chat-footer {
  padding: 20px;
}

.small-text {
  font-size: 12px;
}

.text-right {
  text-align: right;
}

.chat-message-body {
  display: flex;
  margin-bottom: 5px;
  padding: 10px 15px;
}

.user-chat {
  background-color: #f2f2f2;
}

.chat-time {
  color: #919191;
  margin-right: 20px;
  flex-shrink: 0;
}

.user-chat-message {
  font-weight: 600;
  text-align: left;
}

.ai-chat-message {
  text-align: left;
}

.small-loader {
  height: 20px !important;
  width: 20px !important;
  margin-top: 20px;
  margin-bottom: 20px;
}

.flex-center {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-link {
  cursor: pointer;
}

.chat-container-loader-box {
  height: 300px;
}
.login_textfield {
  width: 100%;
  padding: 12;
  border-radius: 4px;
  background-color: #F9F9F9;
  border: 1px solid #E6E6E6;
  font-family: Montserrat, sans-serif;

}
.login_title {
  font-family: Montserrat, sans-serif;
  color: #000;
  text-align: center;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

button.loginButton {
  border-radius: 80px;
  background-color: #6FECE2;
  color: black;
  text-transform: none;
}

button.loginButton:hover {
  background-color: black;
  color: white;
}

.chat-doc-list {
  height: 150px;
  overflow: auto;
}

.chat-doc-chip {
  margin: 5px !important;
  max-width: 199px !important;
}

.footer-btn {
  text-align: center;
  margin: 5px;
}

.download-button {
  width: 100px; /* Adjust as needed */
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.right-align {
  align-self: flex-end;
}
